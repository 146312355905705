import styled from 'styled-components';

export const ScenariosTableItemDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;

	padding: 20px 0;
	gap: 20px;

	& form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
`

export const ButtonWrapper = styled.div`
	display: flex;
	gap: 20px;
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  
  & > div {
    max-width: 250px;
    width: 100%;
  }
`
