import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import TableMenuIcon from 'assets/images/table-menu.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TransactionsTableWrapper } from './TransactionsTable.elements'

// Views
import {
  Image,
  AccordionTable,
  DateRangePicker,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder,
} from 'views/components'

import { TransactionsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters }) => ({ filters })
const mapDispatchToProps = actions

const TransactionsTable = (props) => {
  // Destructure
  const { data, headerOptions, actions, isLoading, filters } = props
  const { monitoringFilters } = filters
  const { setMonitoringFilters } = actions

  // Variables
  const tableHeaders = [
    { name: 'Transaction', sort: true, identifier: 'Hash', width: 250 },
    { name: 'Chain', sort: true, identifier: 'Chain' },
    { name: 'Amount', sort: true, identifier: 'Input_USD' },
    { name: 'Risk Score', sort: true, identifier: 'Transaction_Risk_Score' },
    { name: 'Timestamp', sort: true, identifier: 'CreateTimestamp' },
  ]

  // Functions
  const handleOnTableFilterChange = async (args) => {
    // making sure table goes back to first page
    const changedData = { ...args, pageIndex: 0 }
    await setMonitoringFilters({ ...monitoringFilters, ...changedData })
  }

  return (
    <TransactionsTableWrapper>
      <AccordionTable
        title="Transactions Table"
        tableFilters={monitoringFilters}
        setTableFilters={setMonitoringFilters}
        titleIcon={<Image src={TableMenuIcon} width={30} height={30} />}
        headers={tableHeaders}
        minWidth={700}
        totalItems={data.total && data.total[0]}
        filterComponents={
          <DateRangePicker
            start={monitoringFilters?.start}
            end={monitoringFilters?.end}
            onApply={handleOnTableFilterChange}
          />
        }
        headerOptions={headerOptions}
      >
        {data?.items?.length > 0 && !isLoading ? (
          data.items.map(
            ({
              Transaction_ID,
              Hash,
              Asset,
              Transaction_Risk_Score,
              CreateTimestamp,
              Tx_Timestamp,
              Block_ID,
              Flags,
              Created_By,
              Case_ID,
              Chain,
              Input_Count,
              Input_USD,
              Output_Count,
              Output_USD,
              Notes,
              Token
            }) => (
              <TransactionsTableItem
                key={Transaction_ID}
                id={Transaction_ID}
                transaction={Hash}
                asset={Asset}
                blockId={Block_ID}
                riskScore={Transaction_Risk_Score}
                flags={Flags}
                createdBy={Created_By}
                createTimestamp={CreateTimestamp}
                timeStamp={Tx_Timestamp}
                incoming={Input_Count}
                incomingUsd={Input_USD}
                outgoing={Output_Count}
                outgoingUsd={Output_USD}
                caseId={Case_ID}
                chain={Chain}
                notes={Notes}
                token={Token}
              />
            )
          )
        ) : (
          <>
            {isLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <>{data?.items?.length < 1 ? <EmptyTablePlaceholder /> : <></>}</>
            )}
          </>
        )}
      </AccordionTable>
    </TransactionsTableWrapper>
  )
}

// Default Props
TransactionsTable.defaultProps = {
  actions: {},
  data: {},
  headerOptions: '',
  isLoading: false,
  filters: {},
}

// Proptypes Validation
TransactionsTable.propTypes = {
  actions: PropTypes.shape({
    setMonitoringFilters: PropTypes.func,
  }),
  data: PropTypes.shape({
    items: PropTypes.instanceOf(Array),
    total: PropTypes.instanceOf(Array),
    biz_total: PropTypes.instanceOf(Array),
  }),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  isLoading: PropTypes.bool,
  filters: PropTypes.shape({
    monitoringFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable)
