import styled from 'styled-components'

export const TableDataWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px 120px 120px 1fr 120px;
  gap: 25px;
`

export const TableDataItem = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;  
  text-overflow: ellipsis;

  font-weight: 100;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`
