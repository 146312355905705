import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
`

export const PoliciesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 0 0 0;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 40px 0 30px 0;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1024px;
  width: 100%;
  padding: 0 24px;
`

export const Title = styled.div`
  font-size: 24px;
`

export const PolicyItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
  }
`

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:last-child {
    align-items: flex-end;
    gap: 10px;
  }
`

export const ItemTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;

  color: ${(props) => (props.color ? props.color : '#000')};
`

export const ItemData = styled.div`
  font-weight: 100;
`
export const ItemLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid transparent;

  transition: .3s;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey}
  }
`
