/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

// Assets
import TableMenuIcon from 'assets/images/table-menu.svg'

// Styled Elements
import { TransactionsTableWrapper } from './ScenariosTable.elements'

// Views
import {
  Image,
  AccordionTable,
  EmptyTablePlaceholder,
  LoadingTablePlaceholder,
} from 'views/components'

import { ScenariosTableItem } from 'views/layouts'

const ScenariosTable = (props) => {
  // Destructure
  const {
    isLoading,
    currentPolicy,
    setCurrentPolicy,
    scenarios,
    setScenarios,
  } = props

  // Variables
  const tableHeaders = [
    { name: 'Scenario', identifier: 'scenario', width: 250 },
    { name: 'Severity', identifier: 'Severity', width: 200 },
    { name: 'Type', identifier: 'type', width: 200 },
    { name: 'Status', identifier: 'status', width: 200 },
  ]

  return (
    <TransactionsTableWrapper>
      <AccordionTable
        title="Available Scenarios"
        titleIcon={<Image src={TableMenuIcon} width={30} height={30} />}
        headers={tableHeaders}
        minWidth={700}
      >
        {scenarios?.length > 0 && !isLoading ? (
          scenarios.map(
            ({
              scenario_id,
              scenario_info: { scenarioName, severity, type, status, },
              queryData
            }) => (
              <ScenariosTableItem
                key={scenario_id}
                scenario_id={scenario_id}
                scenarioName={scenarioName}
                severity={severity}
                type={type}
                status={status}
                queryData={queryData}
                currentPolicy={currentPolicy}
                setCurrentPolicy={setCurrentPolicy}
                scenarios={scenarios}
                setScenarios={setScenarios}
              />
            )
          )
        ) : (
          <>
            {isLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <>{scenarios?.length < 1 ? <EmptyTablePlaceholder /> : <></>}</>
            )}
          </>
        )}
      </AccordionTable>
    </TransactionsTableWrapper>
  )
}

// Default Props
ScenariosTable.defaultProps = {
  isLoading: false,
  currentPolicy: {},
  setCurrentPolicy: () => {},
  scenarios: [],
  setScenarios: () => {},
}

// Proptypes Validation
ScenariosTable.propTypes = {
  isLoading: PropTypes.bool,
  currentPolicy: PropTypes.object,
  setCurrentPolicy: PropTypes.func,
  scenarios: PropTypes.array,
  setScenarios: PropTypes.func,
}

export default ScenariosTable
