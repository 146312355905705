import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAlertDetails } from 'core/hooks/api'

// Styled Components
import {
  PageWrapper,
  AlertDetailsWrapper,
  AlertDetailsFormWrapper,
} from './AlertDetails.elements'

// Views
import { Container } from 'views/components'
import { App, Header, Sidebar, Content, AlertDetailsForm, BreachReportTable, AlertsSubHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const AlertDetails = (props) => {
  // Destructure
  const { actions } = props

  // Store Actions
  const { setActiveModule, setShowHeaderLoader, showAlert } = actions

  // States
  const [alertDetailsSummary, setAlertDetailsSummary] = useState({})
  const [alertDetailsReport, setAlertDetailsReport] = useState([])

  // Hooks
  const { alertId } = useParams()
  const {
    getAlertDetails,
    alertDetailsData,
    isAlertDetailsLoading,
    isAlertDetailsError,
  } = useFetchAlertDetails()

  // useEffect
  useEffect(() => {
    // Fetch Cases Data on Render
    getAlertDetails({ alert_id: alertId })
    setActiveModule('alerts')
  }, [alertId])

  useEffect(() => {
    if (alertDetailsData && !isAlertDetailsLoading) {
      setAlertDetailsSummary({ ...alertDetailsData, reports: [] })
      setAlertDetailsReport(alertDetailsData.reports)
    }
  }, [alertDetailsData])

  useEffect(() => {
    if (isAlertDetailsLoading) { return setShowHeaderLoader(true) }
    if (!isAlertDetailsLoading) { return setShowHeaderLoader(false) }
  }, [isAlertDetailsLoading])

  useEffect(() => {
    if (isAlertDetailsError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isAlertDetailsError])

  return (
    <App>
      <Sidebar />
      <PageWrapper>
        <Header subHeader={<AlertsSubHeader />} />
        <Content>
          <AlertDetailsWrapper>
            <Container>
              <AlertDetailsFormWrapper>
                <AlertDetailsForm data={alertDetailsSummary} />
              </AlertDetailsFormWrapper>
              <AlertDetailsFormWrapper>
                <BreachReportTable data={alertDetailsReport} />
              </AlertDetailsFormWrapper>
            </Container>
          </AlertDetailsWrapper>
        </Content>
      </PageWrapper>
    </App>
  )
}

// Default Props
AlertDetails.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
AlertDetails.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object)
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDetails)
