import styled from 'styled-components'

export const EmptyTablePlaceholderWrapper = styled.div`
  padding: 140px 0px;
`

export const EmptyTablePlaceholderImage = styled.div`
  margin: 0px auto 20px auto;
  width: 200px;
`

export const EmptyTablePlaceholderTextWrapper = styled.div`
  margin: auto;
  width: 300px;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const EmptyTablePlaceholderText = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;

  text-align: center;
`

export const EmptyTablePlaceholderSubText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;

  text-align: center;
`
