import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { NumericTextWrapper, NumericCurrencyText } from './NumericText.elements'

const NumericText = (props) => {
  // Destructure
  const { currency, children, title } = props

  return (
    <NumericTextWrapper title={title}>
      <NumericCurrencyText withCurrency={currency}>{currency}</NumericCurrencyText>
      {children}
    </NumericTextWrapper>
  )
}

// Default Props
NumericText.defaultProps = {
  children: '',
  currency: '',
  title: '',
}

// Proptypes Validation
NumericText.propTypes = {
  currency: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
}

export default NumericText
