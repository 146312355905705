import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const BreachReportTableWrapper = styled.div`
`

export const Title = styled.div`
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: 500;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const TableHeaders = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 2fr 3fr;
  gap: 10px;

  padding: 10px 20px 10px 0;
  border-bottom: ${({ theme }) => `solid 2px ${theme.colors.lightGrey}`};
`

export const TableHeaderTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
`

export const TableItem = styled.div`
  position: relative;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 2fr 3fr;
  gap: 10px;

  padding: 10px 20px 10px 0;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.lightGrey}`};
`

export const TableItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;  
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 100;
  height: auto;
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
`

export const TableItemLink = styled(NavLink)`
  display: flex;
  align-items: center;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
`
