import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'

// Assets
import LinkIcon from 'assets/images/icon-link.svg'

// Styled Components
import {
  PageWrapper,
  PoliciesWrapper,
  RowContainer,
  RowWrapper,
  Title,
  PolicyItem,
  ItemDataContainer,
  ItemTitle,
  ItemData,
  ItemLink,
} from './Policies.elements'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Views
import { Container, Button, Image, Form, FormTextField, FormTextArea } from 'views/components'
import { App, Header, Sidebar, Content, AlertsSubHeader } from 'views/layouts'

const Policies = () => {
  // Variables
  const initialValues = {
    policyTitle: '',
    policyDescription: '',
  }
  const validationSchema = Yup.object().shape({})

  // Hooks
  const [policiesList, setPoliciesList] = useLocalStorage('policies', [])

  // States
  const [formValues] = useState(initialValues)
  const [isCreatePolicyActive, setIsCreatePolicyActive] = useState(false)

  // Functions
  const handleOnSubmit = (values) => {
    if (!values.policyTitle || !values.policyDescription) return setIsCreatePolicyActive(false)
    const policyItem = {
      policy_id: uuid(),
      policy_info: {
        ...values,
        status: 'inactive',
      },
      scenarios: []
    }
    setPoliciesList([policyItem, ...policiesList])
    setIsCreatePolicyActive(false)
  }
  return (
    <App>
      <Sidebar />
      <PageWrapper>
        <Header subHeader={<AlertsSubHeader />} />
        <Content>
          <PoliciesWrapper>

            <RowWrapper>
              <RowContainer>
                <Title>Available Policies</Title>
                <Button onClick={() => setIsCreatePolicyActive(true)}>Add Policy</Button>
              </RowContainer>
            </RowWrapper>

            <Container>
              {isCreatePolicyActive &&
                <PolicyItem>
                  <Form
                    enableReinitialize
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleOnSubmit}
                    autoComplete="off"
                  >
                    <FormTextField label="Policy Title" type="text" name="policyTitle" required />
                    <FormTextArea label="Policy Description" name="policyDescription" rows={4} required />
                    <ItemDataContainer>
                      <Button type="submit">Save</Button>
                      <Button color="secondary" onClick={() => setIsCreatePolicyActive(false)}>Cancel</Button>
                    </ItemDataContainer>
                  </Form>
                </PolicyItem>
              }
              {policiesList.map(({ policy_info: { policyTitle, policyDescription, status }, policy_id }) =>
                <PolicyItem key={policy_id}>
                  <ItemDataContainer>
                    <ItemTitle>{policyTitle}</ItemTitle>
                    <ItemData>{policyDescription}</ItemData>
                  </ItemDataContainer>
                  <ItemDataContainer>
                    <ItemTitle color={status === 'active' ? 'green' : 'red'}>{status}</ItemTitle>
                    <NavLink to={`/alerts/policies/${policy_id}`}>
                      <ItemLink>
                        View
                        <Image height={18} width={18} src={LinkIcon} />
                      </ItemLink>
                    </NavLink>
                  </ItemDataContainer>
                </PolicyItem>
              )}
            </Container>
          </PoliciesWrapper>
        </Content>
      </PageWrapper>
    </App>
  )
}

// Default Props
Policies.defaultProps = {
}

// Proptypes Validation
Policies.propTypes = {
}

export default Policies
