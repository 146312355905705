import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import debounce from 'lodash.debounce'

// Assets
import UserPlusIcon from 'assets/images/user-plus.svg'
import CrossHairIcon from 'assets/images/crosshair.svg'
import ReportIcon from 'assets/images/report.svg'
import TrashIcon from 'assets/images/trash-2.svg'
import CopyIcon from 'assets/images/copy.svg'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Utils
import { numberWithCommas, formatChain } from 'common/utils/valueFormat'
import riskScoreColor from 'common/utils/riskScoreColor'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseDetails, useFetchWhitelistingAddressReport, usePatchWhitelistingAddressNotes } from 'core/hooks/api'

// Styled Elements
import {
  AccordionTableButtonWrapper,
  AccordionTableButtonItem,
  AccordionTablePanelWrapper,
  ExpandedAccordionTableButtonWrapper,
  HashWrapper,
  BlurredHashWrapper,
  DropdownItemInner,
  DropdownItemInnerText,
} from './AddressesTableItem.elements'

// Views
import {
  Image,
  AccordionTableItem,
  AccordionTableDetails,
  TextBlur,
  NumericText,
  BadgeText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'views/components'

// Helpers
import { formatAccordionDetails } from './AddressesTableItemHelpers'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

const AddressesTableItem = (props) => {
  // Destructure
  const {
    form,
    actions,
    id,
    address,
    asset,
    balance,
    balanceUsd,
    riskScore,
    flags,
    timestamp,
    latestActivity,
    incoming,
    incomingUsd,
    outgoing,
    outgoingUsd,
    createdBy,
    caseId,
    chain,
    notes,
    token,
  } = props

  // Store State
  const { addressOrTransactionIdToAssign } = form

  // Store Actions
  const {
    openAssignToCaseDrawer,
    setActivityToRemove,
    toggleDeleteAddressModal,
    setInitialNodeData,
    setAddressOrTransactionIdToAssign,
    setShowHeaderLoader,
    showAlert,
  } = actions

  // States
  const [accordionDetails, setAccordionDetails] = useState()

  // Hooks
  const history = useHistory()
  const { caseDetailsData, getCaseDetails, isCaseDetailsLoading, isCaseDetailsError } = useFetchCaseDetails()
  const { patchWhitelistingAddressNotes, isWhitelistingAddressNotesError } = usePatchWhitelistingAddressNotes()
  const {
    getWhitelistingAddressReport,
    whitelistingAddressReportData,
    isWhitelistingAddressReportLoading,
  } = useFetchWhitelistingAddressReport()

  const [isExpanded, setIsExpanded] = useState(false)
  const [dataRetrieved, setDataRetrieved] = useState(false) // Making sure no data is retrieved multiple times

  // Variables
  const accordionData = {
    asset,
    balance,
    balanceUsd,
    riskScore,
    flags,
    incoming,
    incomingUsd,
    outgoing,
    outgoingUsd,
    createdBy,
    notes,
    token,
  }
  const isLoading = (isCaseDetailsLoading || isWhitelistingAddressReportLoading)
  const notLoading = (!isCaseDetailsLoading && !isWhitelistingAddressReportLoading)
  const isCaseUpdated = (addressOrTransactionIdToAssign.id === id && !!addressOrTransactionIdToAssign.caseId)
  const chainName = formatChain(chain)

  // Functions
  const handleOpenAssignCaseDrawer = () => {
    setAddressOrTransactionIdToAssign({ type: 'address', id })
    openAssignToCaseDrawer()
  }

  const handleOpenRemoveActivityModal = () => {
    setActivityToRemove({ type: 'address', hash: id })
    toggleDeleteAddressModal()
  }

  const handleOnInvestigationClick = () => {
    setInitialNodeData({
      type: 'address',
      hash: address,
      asset,
      chain,
      value: balance,
      valueUsd: balanceUsd,
      riskScore,
      color: riskScoreColor(riskScore),
      flags,
      direction: 'incoming',
      timeStamp: latestActivity,
      children: [],
    })

    history.push(`/investigation/${address}`)
  }

  const saveNote = debounce((value, setIsNoteLoading) => {
    patchWhitelistingAddressNotes({ address_id: id, notes: value })
    setIsNoteLoading(true)
    setTimeout(() => { setIsNoteLoading(false) }, 500)
  }, 500)

  // useEffect
  useEffect(() => {
    formatAccordionDetails({}, accordionData, setAccordionDetails)
  }, [])

  useEffect(() => {
    if (isExpanded && !dataRetrieved) {
      if (caseId.length >= 26) {
        getCaseDetails({ case_id: caseId })
      } else {
        setDataRetrieved(true)
      }
    }
  }, [isExpanded])
  useEffect(() => {
    if (isCaseDetailsError) {
      formatAccordionDetails({}, accordionData, setAccordionDetails)
      return setDataRetrieved(true)
    }
    if (caseDetailsData && isExpanded && !dataRetrieved) {
      formatAccordionDetails({ caseDetailsData }, accordionData, setAccordionDetails)
      return setDataRetrieved(true)
    }
  }, [caseDetailsData, isExpanded, isCaseDetailsError])
  // updating the table item when case is assigned
  useEffect(() => {
    if (isCaseUpdated) {
      setDataRetrieved(false)
      getCaseDetails({ case_id: addressOrTransactionIdToAssign.caseId })
      setAddressOrTransactionIdToAssign({ type: '', id: '', caseId: '' })
    }
  }, [addressOrTransactionIdToAssign])
  // opening Monitoring Transaction Report Data
  useEffect(() => {
    if (whitelistingAddressReportData) {
      window.open(whitelistingAddressReportData, '_blank')
    }
  }, [whitelistingAddressReportData])

  useEffect(() => {
    if (isLoading) {
      setShowHeaderLoader(true)
    } else if (notLoading) {
      setShowHeaderLoader(false)
    }
  }, [isCaseDetailsLoading, isWhitelistingAddressReportLoading])

  useEffect(() => {
    if (isWhitelistingAddressNotesError) {
      showAlert({ type: 'error', message: 'An error occured in saving the notes' })
    }
  }, [isWhitelistingAddressNotesError])

  return (
    <AccordionTableItem
      setIsExpanded={setIsExpanded}
      expandPanel={isExpanded}
      button={({ expanded }) => {
        const startHashText = address.substring(0, 5)
        const endHashText = address.slice(-5)

        return !expanded ? (
          <AccordionTableButtonWrapper>
            <AccordionTableButtonItem width={250}>
              <BlurredHashWrapper>
                {startHashText}
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                {endHashText}
              </BlurredHashWrapper>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>{chainName}</AccordionTableButtonItem>
            <AccordionTableButtonItem>
              <NumericText currency="USD">{numberWithCommas(balanceUsd)}</NumericText>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>
              <BadgeText color={riskScoreColor(riskScore)}>{riskScore}</BadgeText>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>{moment(timestamp).format(DASHED_DATE_FORMAT)}</AccordionTableButtonItem>
          </AccordionTableButtonWrapper>
        ) : (
          <ExpandedAccordionTableButtonWrapper>
            <HashWrapper
              data-for-toggle="false"
              data-value={address}
              onClick={() => { navigator.clipboard.writeText(address) }}
            >
              {address}
              <Image src={CopyIcon} width={16} height={16} />
            </HashWrapper>
            <UncontrolledDropdown direction="down" data-for-toggle="false">
              <DropdownToggle>
                <Button>Action</Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem onClick={handleOpenAssignCaseDrawer}>
                  <DropdownItemInner>
                    <Image src={UserPlusIcon} width={16} height={16} />
                    <DropdownItemInnerText>Assign</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
                {(chain === 'bitcoin' || chain === 'ethereum') && (
                  <DropdownItem onClick={handleOnInvestigationClick}>
                    <DropdownItemInner>
                      <Image src={CrossHairIcon} width={16} height={16} />
                      <DropdownItemInnerText>Investigate</DropdownItemInnerText>
                    </DropdownItemInner>
                  </DropdownItem>
                )}
                <DropdownItem>
                  <DropdownItemInner onClick={() => { getWhitelistingAddressReport({ address_id: id }) }}>
                    <Image src={ReportIcon} width={16} height={16} />
                    <DropdownItemInnerText>Report</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
                <DropdownItem onClick={handleOpenRemoveActivityModal}>
                  <DropdownItemInner>
                    <Image src={TrashIcon} width={16} height={16} />
                    <DropdownItemInnerText>Remove</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ExpandedAccordionTableButtonWrapper>
        )
      }}
      panel={
        <AccordionTablePanelWrapper>
          {accordionDetails && (
            <AccordionTableDetails
              key={id}
              data={accordionDetails}
              type="address"
              onTextChange={saveNote}
              caseLoading={isCaseDetailsLoading}
            />
          )}
        </AccordionTablePanelWrapper>
      }
    />
  )
}

// Default Props
AddressesTableItem.defaultProps = {
  actions: {},
  form: {},
  address: '',
  asset: '',
  balance: 0,
  balanceUsd: 0,
  riskScore: 0,
  latestActivity: '',
  timestamp: '',
  caseId: '',
  flags: '',
  createdBy: '',
  incoming: 0,
  incomingUsd: 0,
  outgoing: 0,
  outgoingUsd: 0,
  chain: '',
  notes: '',
  token: '',
}

// Proptypes Validation
AddressesTableItem.propTypes = {
  actions: PropTypes.shape({
    openAssignToCaseDrawer: PropTypes.func,
    setActivityToRemove: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setInitialNodeData: PropTypes.func,
    setAddressOrTransactionIdToAssign: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  form: PropTypes.shape({
    addressOrTransactionIdToAssign: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      caseId: PropTypes.string,
    }),
  }),
  id: PropTypes.string,
  address: PropTypes.string,
  asset: PropTypes.string,
  balance: PropTypes.number,
  balanceUsd: PropTypes.number,
  riskScore: PropTypes.number,
  timestamp: PropTypes.string,
  latestActivity: PropTypes.string,
  caseId: PropTypes.string,
  flags: PropTypes.string,
  createdBy: PropTypes.string,
  incoming: PropTypes.number,
  incomingUsd: PropTypes.number,
  outgoing: PropTypes.number,
  outgoingUsd: PropTypes.number,
  chain: PropTypes.string,
  notes: PropTypes.string,
  token: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTableItem)
