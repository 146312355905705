import React from 'react'
import PropTypes from 'prop-types'

// Assets
import LinkIcon from 'assets/images/icon-link.svg'

// Utils
import riskScoreColor from 'common/utils/riskScoreColor'

// Styled Elements
import { TableDataWrapper, TableDataItem } from './AlertsTableItem.elements'

// Views
import { TableItem, Image, BadgeText } from 'views/components'

import { NavLink } from 'react-router-dom'

const AlertsTableItem = (props) => {
  // Destructure
  const { alert_id, severity, alert_status, rule_description, timestamp } = props

  return (
    <TableItem
      key={alert_id}
      endIcon={
        <NavLink to={`/alerts/details/${alert_id}`}>
          <Image height={18} width={18} src={LinkIcon} />
        </NavLink>
      }
    >
      <TableDataWrapper>
        <TableDataItem width={200}>{alert_id}</TableDataItem>
        <TableDataItem width={120}>
          <BadgeText color={riskScoreColor(severity)}>{severity[0]}</BadgeText>
        </TableDataItem>
        <TableDataItem width={120}>{alert_status}</TableDataItem>
        <TableDataItem>{rule_description}</TableDataItem>
        <TableDataItem width={120}>{timestamp}</TableDataItem>
      </TableDataWrapper>
    </TableItem>
  )
}

// Default Props
AlertsTableItem.defaultProps = {
  alert_id: '',
  severity: '',
  alert_status: '',
  rule_description: '',
  timestamp: ''
}

// Proptypes Validation
AlertsTableItem.propTypes = {
  alert_id: PropTypes.string,
  severity: PropTypes.string,
  alert_status: PropTypes.string,
  rule_description: PropTypes.string,
  timestamp: PropTypes.string
}

export default AlertsTableItem
