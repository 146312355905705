import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
`

export const PoliciesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 120px 0 0 0;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  padding: 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};
`

export const Title = styled.div`
  font-size: 24px;
`

export const PolicyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;

  padding: 20px;
  gap: 20px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;

    gap: 20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
  }
`

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  &:last-child {
    align-items: flex-end;
    gap: 10px;
  }
`

export const ItemData = styled.div`
  font-weight: 100;
`

export const ItemTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;

  color: ${(props) => (props.color ? props.color : '#000')};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & > div:last-child {
    max-width: 120px;
    width: 100%;
  }
  & > div:first-child {
    max-width: 300px;
    width: 100%;
  }
`
