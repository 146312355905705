import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import { AlertsTableWrapper } from './AlertsTable.elements'

// Views
import { Table, EmptyTablePlaceholder, LoadingTablePlaceholder, DateRangePicker } from 'views/components'
import { AlertsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
const AlertsTable = (props) => {
  // Destructure
  const { data, isLoading } = props

  // Variables
  const tableHeaders = [
    { name: 'Alert ID', width: 200 },
    { name: 'Severity', width: 120 },
    { name: 'Status', width: 120 },
    { name: 'Summary', },
    { name: 'Timestamp', width: 120 },
  ]

  // States
  const [alertsData, setAlertsData] = useState([])

  useEffect(() => {
    if (data) { setAlertsData(data) }
  }, [data])

  return (
    <AlertsTableWrapper>
      <Table
        title="Alerts Table"
        headers={tableHeaders}
        withEndIcon
        gap={25}
        minWidth={900}
        filterComponents={<DateRangePicker />}
      >
        {(alertsData.length > 0 && !isLoading) ? (
          alertsData.map(({ alert_id, severity, alert_status, rule_description, timestamp }, i) => (
            <AlertsTableItem
              key={`${alert_id}${i}`}
              alert_id={alert_id}
              severity={severity}
              alert_status={alert_status}
              rule_description={rule_description}
              timestamp={moment(timestamp).format(DASHED_DATE_FORMAT)}
            />
          ))
        ) : (
          <>
            {isLoading ?
              <LoadingTablePlaceholder />
            :
              <>
                {alertsData.length < 1 ?
                  <EmptyTablePlaceholder />
                :
                  <></>
                }
              </>
            }
          </>
        )}
      </Table>
    </AlertsTableWrapper>
  )
}

// Default Props
AlertsTable.defaultProps = {
  isLoading: false,
  data: []
}

// Proptypes Validation
AlertsTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    alert_id: PropTypes.string,
    breach_count: PropTypes.number,
    primary_dataset: PropTypes.number,
    severity: PropTypes.string,
    alert_status: PropTypes.string,
    timestamp: PropTypes.string,
    rule_description: PropTypes.string,
    rule_code: PropTypes.string
  })),
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsTable)
