/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AccordionTableButtonWrapper,
  AccordionTableButtonItem,
  AccordionTablePanelWrapper,
  ExpandedAccordionTableButtonWrapper,
} from './ScenariosTableItem.elements'

// Views
import { AccordionTableItem } from 'views/components'
import { ScenariosTableItemDetails } from 'views/layouts'

const TransactionsTableItem = (props) => {
  // Destructure
  const {
    scenario_id,
    scenarioName,
    severity,
    type,
    status,
    queryData,
    currentPolicy,
    setCurrentPolicy,
    scenarios,
    setScenarios,
  } = props

  // States
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <AccordionTableItem
      setIsExpanded={setIsExpanded}
      expandPanel={isExpanded}
      button={({ expanded }) => (
        !expanded ? (
          <AccordionTableButtonWrapper>
            <AccordionTableButtonItem width={250}>{scenarioName}</AccordionTableButtonItem>
            <AccordionTableButtonItem width={200}>{severity}</AccordionTableButtonItem>
            <AccordionTableButtonItem width={200}>{type}</AccordionTableButtonItem>
            <AccordionTableButtonItem width={200}>{status}</AccordionTableButtonItem>
          </AccordionTableButtonWrapper>
        ) : (
          <ExpandedAccordionTableButtonWrapper>
            <AccordionTableButtonWrapper>
              <AccordionTableButtonItem width={400}>Close Item</AccordionTableButtonItem>
            </AccordionTableButtonWrapper>
          </ExpandedAccordionTableButtonWrapper>
        ))}
      panel={
        <AccordionTablePanelWrapper>
          <ScenariosTableItemDetails
            key={scenario_id}
            queryData={queryData}
            scenario_id={scenario_id}
            scenarioName={scenarioName}
            severity={severity}
            type={type}
            status={status}
            isExpanded={isExpanded}
            currentPolicy={currentPolicy}
            setCurrentPolicy={setCurrentPolicy}
            scenarios={scenarios}
            setScenarios={setScenarios}
          />
        </AccordionTablePanelWrapper>
      }
    />
  )
}

// Default Props
TransactionsTableItem.defaultProps = {
  scenario_id: '',
  scenarioName: '',
  severity: '',
  type: '',
  status: '',
  queryData: {},
  currentPolicy: {},
  setCurrentPolicy: () => {},
  scenarios: [],
  setScenarios: () => {},
}

// Proptypes Validation
TransactionsTableItem.propTypes = {
  scenario_id: PropTypes.string,
  scenarioName: PropTypes.string,
  severity: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  queryData: PropTypes.object,
  currentPolicy: PropTypes.object,
  setCurrentPolicy: PropTypes.func,
  scenarios: PropTypes.array,
  setScenarios: PropTypes.func,
}

export default TransactionsTableItem
