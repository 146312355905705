import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';

// Config Data
import { configData as config, configDataReadOnly as configReadOnly } from './configData'
import { defaultInitValue } from './initvalue'

// ANTD Styles
import 'react-awesome-query-builder/lib/css/styles.css';
import 'antd/dist/antd.css';

// Styled Components
import {
	ScenarioBuilderWrapper
} from './ScenarioBuilder.elements'
import { useEffect } from 'react';

const ScenarioBuilder = (props) => {
	const {
    initValue,
    setQueryData,
    readOnly,
    isCancelled,
    setIsCancelled,
    isSaved,
    setIsSaved,
  } = props

  const [state, setState] = useState({
    tree: QbUtils.checkTree(
        QbUtils.loadTree(Object.keys(initValue).length !== 0 ? initValue : defaultInitValue),
        (readOnly ? configReadOnly : config)
      ),
    config: readOnly ? configReadOnly : config
  });
  const [oldState, setOldState] = useState({
    tree: QbUtils.checkTree(
        QbUtils.loadTree(Object.keys(initValue).length !== 0 ? initValue : defaultInitValue),
        (readOnly ? configReadOnly : config)
      ),
    config: readOnly ? configReadOnly : config
  })

  const onChange = (immutableTree, config) => {
    setState({ tree: immutableTree, config });

    const jsonTree = QbUtils.getTree(immutableTree);
    setQueryData(jsonTree);
  };

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  useEffect(() => {
    if (Object.keys(initValue).length < 1) setQueryData(defaultInitValue);
  }, [])

  useEffect(() => {
    if (isSaved) {
      setIsSaved(false)
      return setOldState(state)
    }
    if (isCancelled) {
      setIsCancelled(false)
      return setState(oldState)
    }
  }, [isSaved, isCancelled])
  return (
    <ScenarioBuilderWrapper>
      <Query
        {...(readOnly ? configReadOnly : config)}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </ScenarioBuilderWrapper>
  )
}

// Default Props
ScenarioBuilder.defaultProps = {
	initValue: {},
  setQueryData: () => {},
  readOnly: false,
  isCancelled: false,
  setIsCancelled: () => {},
  isSaved: false,
  setIsSaved: () => {},
}

// Proptypes Validation
ScenarioBuilder.propTypes = {
	initValue: PropTypes.shape({}),
	setQueryData: PropTypes.func,
  readOnly: PropTypes.bool,
  isCancelled: PropTypes.bool,
  setIsCancelled: PropTypes.func,
  isSaved: PropTypes.bool,
  setIsSaved: PropTypes.func,
}

export default ScenarioBuilder
