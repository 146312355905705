import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
`

export const AlertsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 120px 0 0 0;
`

export const AlertsBreakdownWrapper = styled.div`
  display: flex;
  gap: 10px; 
  width: 100%;
  margin: 20px 0px 0px 0px;
  
  ${({ theme }) => theme.functions.mediaQuery('flex-direction',
    ['column', 'column', 'column', 'column', 'column', 'column', 'row']
  )}
`

export const AlertsBreakdownColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  max-width: 50%;

  ${({ theme }) => theme.functions.mediaQuery('max-width',
    ['100%', '100%', '100%', '100%', '100%', '100%', '50%']
  )}
`
export const AlertsBreakdownRowWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const AlertsBreakdownTableWrapper = styled.div`
  padding: 15px 0px 0px 0px;
`
