import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
`

export const AlertDetailsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 120px 0 0 0;
`

export const AlertDetailsFormWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-radius: 5px;

  &:first-child {
    margin-top: 60px;
  }

  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
`
